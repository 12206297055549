import { ComponentProps } from 'react'

import { Colors } from '../types'
import styles from './styles.module.scss'

interface TagProps extends ComponentProps<'div'> {
  size?: 'md' | 'sm'
  color?: Colors | 'element' | 'inverse' | 'outlined'
  singleColor?: boolean
}

const Tag = ({
  className,
  size = 'sm',
  singleColor = false,
  color = 'brand',
  ...props
}: TagProps) => (
  <div
    {...props}
    className={[
      styles.tag,
      styles[color],
      styles[size],
      singleColor && styles.singleColor,
      className,
    ].join(' ')}
  />
)

export default Tag
